import { useContext, useMemo, useState } from "react";

import { useGetGas, useToast } from '@/hooks';
import { useClientProvider } from "client/ClientProvider";
import { handleAccountInsufficient } from "@/utils";
import { useTokenList } from 'hooks';
import { sendMessage } from "utils";
import { Bugsnag } from 'bugsnag';
import { TaskContext } from "@/utils/global";
import { compare } from "compare-versions";

export const useClaimBadge = () => {
  const { customerApi, ospClient } = useClientProvider()
  const Toast = useToast();
  const { tokenList } = useTokenList();
  const { getGasFee } = useGetGas();
  // 上链的查询数据结构（预估gas、领取）
  const [userOps, setUserOps] = useState<any>()
  // 页面上的 Claim 按钮 loading 状态
  const [isClaiming, setIsClaiming] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  // 预估gas的展示弹窗
  const [isShowGas, setIsShowGas] = useState(false);
  const { state: { userInfo } } = useContext(TaskContext);
  const { appVersion, platformOS } = userInfo || {};
  // gas费 原生币
  const symbol = useMemo(() => {
    const token = tokenList.find((item: any) => item.type === 'native');
    return token?.symbol || "";
  }, [tokenList]);

  const getUserOps = async (badges: any) => {
    const res = await customerApi.wallets.claimBadges({ids: badges}, {});
    if(!res?.data.success) {
      Bugsnag.notify(new Error('claimBadges error: ' + JSON.stringify(res.data) + JSON.stringify({ids: badges}) ));
      Toast.show("System error");
      setIsClaiming(false);
      return Promise.reject(res?.data.msgKey)
    }
    const claimData = res.data.obj;

    return {
      to: claimData?.target_address,
      value: claimData?.value,
      data: claimData?.call_data,
    };

  }

  const toogleTransitionLoading = (isShow: boolean) => {
    // 调用somon _Layout 里的公用弹窗 <TransitionLoadingNew />
    const messageData = {
      action: 'popup',
      router: 'toogleTransitionLoading',
      params: {
        isShow
      }
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  }

  const onClaim = async (badges: string[]) => {
    const isBefore = compare(
      appVersion || '0.0.1',
      '1.5.0',
      "<",
    );
    if(isBefore && platformOS !== 'web') {
      Toast.show("You need to upgrade to the latest version.");
      return
    } 
    if (!badges?.length) {
      return;
    }
    setIsClaiming(true);
    try {
      const IUserOps = await getUserOps(badges)
        // @ts-ignore
      const { data: gasData, error: gasError } = await getGasFee(IUserOps);
      setIsClaiming(false);
      if (!gasError) {
        sendMessage(JSON.stringify({
          action: 'CLAIM_BADGE',
          payload: {
            userOps: IUserOps,
            gas: gasData,
          },
        }));
        setIsClaiming(false);
      }
    } catch (error) {
      setIsClaiming(false);
      Toast.show("System error");
      return Promise.reject(error)
    }
  };

  return {
    onClaim,
    isClaiming,
    setIsClaiming,
    isConfirming,
    isShowGas,
    setIsShowGas,
    symbol,
    toogleTransitionLoading
  };
};
