import { useState, useContext, useMemo } from 'react'
import { BadgeList } from './BadgeList';
import { LadderItem } from './LadderItem';
import { cn, compare, sendMessage, dayjs } from 'utils';
import { useEffect } from 'react';
// @ts-ignore
import ClaimSvg from "/src/assets/claim.svg?react";
// @ts-ignore
import HistoryLogo from "/src/assets/history.svg?react";
import { useGlobalLadder } from '@/client';
import { TaskContext } from '@/utils/global';
import { LeaderList } from './LeaderList';
import { useGetUnclaimedList } from "../ClaimBadges/hooks";
import { LeaderBoardSkeleton } from './LeaderBoardSkeleton';
import { ClaimModal } from "./ClaimModal";
import { BoardEmpty } from "./BoardEmpty";
import { BADGE_LIST } from '@/consts';
import { useClientProvider } from 'client';
// @ts-ignore
import NextLogo from "/src/assets/arrow-right.svg?react";
import { useSeasonConfig, useToast } from '@/hooks';
import { getValue } from 'firebase';

type GetMinimumScoreRankProps = {
  level: string;
  globalLadder: any[];
}

export const getMinimumScoreRank = ({ level, globalLadder }: GetMinimumScoreRankProps) => {
  if (!level) return 0

  let minimumScoreRank = 0;
  if (Number(level) < 6) {
    const fulfillMinScoreItem = ([...(globalLadder || [])].slice(0, 10).reverse()).find(item => item?.point >= BADGE_LIST[level]?.minPoint)
    minimumScoreRank = fulfillMinScoreItem?.rank || 0;  // 前十名中最后一个满足最低分要求的用户
  } else {
    /** 王者前15保级 */
    const kingList = ([...(globalLadder || [])]);
    minimumScoreRank = kingList.length > 15 ? 15 : 0;
  }
  return minimumScoreRank;
}

export const LeaderBoard = () => {
  const Toast = useToast();
  const { state: { userInfo }, isFirebaseReady } = useContext(TaskContext);
  const { appVersion, platformOS } = userInfo || {};
  const [visible, setVisible] = useState(false);
  const { data, refetch, isError, isLoading } = useGlobalLadder({ idToken: userInfo?.idToken });
  const { seasonConfig, isFetching, currentSeasonId, getSeasonInfo } = useSeasonConfig();
  const currentSeason = getSeasonInfo(currentSeasonId);
  const { unclaimedList } = useGetUnclaimedList(false);
  const [showLeaderBoard, setShowLeaderBoard] = useState(true);
  const [isFirebaseFetching, setIsFirebaseFetching] = useState(true);
  const { isPC } = useClientProvider();

  useEffect(() => {
    const getConfig = async () => {
      let value = {
        showLeaderBoard: true,
      };

      try {
        const leader_board_config = await getValue("leader_board_config");
        value = JSON.parse(leader_board_config.asString() || "") || [];
      } catch (err) {
        console.log(err, 'line56')
      }
      setIsFirebaseFetching(false);
      setShowLeaderBoard(value?.showLeaderBoard);
    }
    isFirebaseReady && getConfig();
  }, [isFirebaseReady]);

  // 使用app打开 & 版本小于1.6.0 不展示owo入口
  const isShowOwoEntry = !(window?.ReactNativeWebView && (!!appVersion && compare(
    appVersion,
    "1.6.0",
    "<"
  )));

  const isShowLeaderBoard = useMemo(() => {
    if(!!seasonConfig?.length && !!currentSeason?.id) {
      return currentSeason?.leaderBoardStatus === 1;
    }
    return false;
  }, [seasonConfig, currentSeason]);

  const toBuyOwo = () => {
    const messageData = {
      action: 'popup',
      router: 'Owo'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const onCancel = () => {
    setVisible(false);
  }
  useEffect(() => {
    const isClaimTime = localStorage.getItem("isClaimTime");
    let isHasNewBadge = !!unclaimedList.length && (!isClaimTime || !!isClaimTime && isClaimTime < unclaimedList?.[0]?.created)
    if (isHasNewBadge) {
      setVisible(true)
      localStorage.setItem("isClaimTime", unclaimedList?.[0]?.created);
    }
  }, [unclaimedList])
  useEffect(() => {
    if (!!userInfo?.idToken) {
      refetch();
    }
  }, [userInfo]);

  const toHistory = () => {
    const isBefore = compare(
      appVersion || '0.0.1',
      '1.5.0',
      "<",
    );
    if (platformOS !== 'web' && isBefore) {
       Toast.show("You need to upgrade to the latest version.");      
    } else {
      const messageData = {
        action: 'navigate',
        router: 'LeaderBoardHistory'
      };
      const message = JSON.stringify(messageData);
      sendMessage(message);
    }
  };

  if (isFetching || isLoading || !currentSeason?.id || isFirebaseFetching) {
    return <LeaderBoardSkeleton />;
  }

  if(!showLeaderBoard) {
    return (
      <BoardEmpty
        image="img/leaderboard/coming-soon.webp"
        title="Ranking Calculating"
        desc="Meanwhile, explore posts and Tribes to earn Points and rank high later!"
      />
    )
  }
  if (!isShowLeaderBoard) {
    // 
    const { previousSettleStatus, settleStatus, startTime, endTime, id } = currentSeason;
    let curSeasonId = id - 1;
    let curEndTime = startTime;
    if(settleStatus === 0 && previousSettleStatus == 2) {
      curEndTime = endTime;
      curSeasonId = id;
    }

    const seasonEndTime = curEndTime ? dayjs(curEndTime).utc().format("MMM D, YYYY") : "";

    return (
      <div className="flex flex-col items-center justify-center h-dvh">
        <BoardEmpty
          image="img/leaderboard/calculating-coming-soon.png"
          title={`SEASON ${curSeasonId} ENDED`}
          desc={`Season ${curSeasonId + 1} starts on ${seasonEndTime} Get ready for a rank reset!`}
          className='h-auto'
          imgClassName="w-[140px] h-[100px] mb-10"
          titleClassName="font-Oswald mb-[10px]"
        />
        <div onClick={toHistory} className='px-4 py-2 flex items-center justify-center cursor-pointer font-SFProDisplay mt-6 font-bold bg-Overlay-Light-Overlay20/20 whitespace-nowrap rounded-[36px] text-Content-Primary text-xs leading-5'>
          <HistoryLogo className="w-5 h-5 mr-1" />
          History
        </div>
        <ClaimModal visible={visible} onCancel={onCancel} />
      </div>
    )
  }
  if (isError || !data) {
    return (
      <BoardEmpty
        image="img/leaderboard/coming-soon.webp"
        title="Ranking Calculating"
        desc="Meanwhile, explore posts and Tribes to earn Points and rank high later!"
      />
    )
  }

  const minimumScoreRank = getMinimumScoreRank({
    level: data?.myLadder?.level,
    globalLadder: data?.globalLadder
  })

  return (
    <div>
      <div className={cn('sticky bg-Backgroun-Primary top-0 w-full pb-1')}>
        <BadgeList currentLevel={data?.myLadder.level}/>
        <div className='flex justify-between items-center px-4 mb-4 h-[70px]'>
          <div>
            <p className={cn(`text-Content-Primary text-[28px] font-bold`)}>{BADGE_LIST[data?.myLadder.level]?.name} League</p>
            <p className='text-xs text-Content-Primary font-SFProDisplay font-bold'>Week {data?.cycle} ends at 0:00 {dayjs(data?.endTime).format('MMMM D')} UTC</p>
            <p className='text-xs text-Content-Tertiary font-SFProDisplay font-normal'>{BADGE_LIST[data?.myLadder.level]?.desc}</p>
          </div>
          <div className="bg-Overlay-Light-Overlay20/20 rounded-full flex flex-col justify-center items-center w-9 h-9" onClick={toHistory}>
            <HistoryLogo className="w-5 h-5" />
          </div>
        </div>
      </div>
      <div className={cn('bg-Backgroun-Secondary rounded-t-3xl', {
        'bg-Backgroun-Primary' : isPC,
        "pt-4": isShowOwoEntry
      })}>
        {isShowOwoEntry && <div className={`bg-[url("/img/leaderboard/owoBg.webp")] bg-cover bg-center mx-4 px-4 py-2 rounded-xl flex justify-between items-center cursor-pointer`} onClick={toBuyOwo}>
          <div className="flex items-center">
            <div className="border-[2px] rounded-full	border-Content-Inverse-Primary-BG">
              <osp-image src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/points-icon.svg`} class=" w-6 h-6" />
            </div>
            <div className="text-Content-Inverse-Primary-BG font-Oswald text-base ml-[6px]">HOW TO CLIMB RANKS FASTER?</div>
          </div>
          <div className="bg-Content-Inverse-Primary-BG rounded-xl p-1">
            <NextLogo className="text-Content-Accent1" />
          </div>
        </div>}
        <LeaderList globalLadder={data?.globalLadder} myLadder={data?.myLadder} userInfo={userInfo} minimumScoreRank={minimumScoreRank}/>
        <LadderItem {...data?.myLadder} userInfo={userInfo}/>
        <ClaimModal visible={visible} onCancel={onCancel}/>
      </div>
    </div>
  )
}