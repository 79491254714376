import { BADGE_LIST, queryKeyMap } from "@/consts";
import { defaultCount, settleStatusEum } from "../const";
import { useContext, useEffect } from "react";
import { TaskContext } from "@/utils/global";
import { useQuery } from "@tanstack/react-query";
import { useClientProvider } from "client";
import { useToast, useCustomerMe, useFirebaseSeasonConfig } from "@/hooks";
import { transform } from "osp-proxy";

export const useAchievementDetail = (props: { seasonId: number }) => {
  const {
    state: { userInfo },
  } = useContext(TaskContext);
  const { customerApi } = useClientProvider();
  const { seasonId } = props;
  const { firebaseSeasonConfig, curFirebaseSeasonConfig } = useFirebaseSeasonConfig();
  
  const Toast = useToast();
  const defaultVipIcon = firebaseSeasonConfig[1]?.lightMemberIcon;

  const formatData = (data: any) => {
    const badgeKeys = Object.keys(data?.badge || {});
    let badgeCount = 0;
    let tribes = [];
    let pfpArr = [
      { image: `${process.env.VITE_PUBLIC_BASE_PATH}/img/pfpDefaultIcon.svg` },
      { image: `${process.env.VITE_PUBLIC_BASE_PATH}/img/pfpDefaultIcon.svg` },
      { image: `${process.env.VITE_PUBLIC_BASE_PATH}/img/pfpDefaultIcon.svg` },
    ];
    if (!!data?.pfp && !!data?.pfpCount) {
      const minCount = Math.min(data.pfpCount, 3);
      try {
        // 只展示最新三个pfp
        const formatPfp = JSON.parse(data.pfp).slice(0, minCount);
        pfpArr.splice(0, formatPfp?.length, ...formatPfp);
      } catch (err) {}
    }
    if(!!data?.tribe) {
      try {
        const formatTribe = JSON.parse(data.tribe).slice(0, 6);
        tribes = formatTribe?.map((item: any) => ({image: item?.logo}));
      } catch (err) {
        tribes = [];
      }
    }
    return {
      ...data,
      badges: badgeKeys.map((key) => {
        const curBadgeCount = data?.badge?.[key]?.count;
        if (typeof curBadgeCount === "number") {
          badgeCount += curBadgeCount;
        }
        return {
          ...data?.badge?.[key],
          type: key,
          count: curBadgeCount || defaultCount,
        };
      }),
      badgeCount: badgeCount || defaultCount,
      pfp: pfpArr,
      tribes: {
        list: tribes,
        total: data?.tribeCount,
      },
      vipIcon: firebaseSeasonConfig[seasonId]?.lightMemberIcon || defaultVipIcon,
      previousVipIcon: firebaseSeasonConfig[data?.season - 1]?.lightMemberIcon || defaultVipIcon,
      maxPfpCount: data?.pfpCount > data?.pfpMarkup ? data?.pfpMarkup : data?.pfpCount || defaultCount,
      pfpCount: data?.pfpCount || defaultCount,
      pfpPortion: data?.pfpPortion || defaultCount,
      vipCount: data?.vipCount || defaultCount,
      topRanked: BADGE_LIST[data?.topLeaderBoardLevel] || {
        name: defaultCount,
      },
    };
  };

  const { data: achievementDetail } = useQuery({
    queryKey: [queryKeyMap.ACHIEVEMENT_DETAIL, seasonId, userInfo?.idToken],
    enabled: !!userInfo?.idToken && !!seasonId,
    async queryFn() {
      // 获取token配置
      const { data } = await customerApi.seasons.season(seasonId);

      if (!data?.success) {
        Toast.show("System error");
      }

      return transform(data?.obj || {});
    },
    select(data) {
      return formatData(data);
    },
    placeholderData: {},
  });

  const {data: pointData, refetch: refetchPoint } = useCustomerMe();

  useEffect(() => {
    if (achievementDetail?.settleStatus !== settleStatusEum.UN_SETTLE) return;
    if (
      achievementDetail?.previousSettleStatus === null ||
      achievementDetail?.previousSettleStatus === settleStatusEum.SETTLED
    ) {
      refetchPoint();
    }
  }, [achievementDetail]);

  return {
    achievementDetail,
    pointData,
    shareText: curFirebaseSeasonConfig?.shareText || "",
  };
};
