import { cn, formatPrettyValue, dayjs } from "utils";
import { useMemo } from "react";
import { settleStatusEum } from "./const";

interface AirdropHeaderType {
  isExpired: boolean;
  settleStatus: number;
  currentId: number;
  owoCount: string;
  claimEndTime: string;
  endTime: string;
}



export const AirdropHeader = (props: AirdropHeaderType) => {
  const { isExpired, currentId, settleStatus, owoCount, claimEndTime, endTime } = props;
  const claimMessage = isExpired ? "Claim period ended" : `Claim before ${dayjs(claimEndTime).utc().format("MMM D, YYYY HH:mm:ss")} UTC`;
  const owoValue = useMemo(() => {
    const value = {
      0: '?????',
      1: 'Calculating…',
      2: formatPrettyValue(owoCount?.toString())
    } as any;
    const owoValue = value[settleStatus] || '0';
    return owoValue;
  }, [settleStatus, currentId]);
  return (
    <div className="w-full flex flex-col items-center pt-3">
        <span className="text-sm font-SFProDisplay font-bold whitespace-nowrap text-Content-Inverse-Primary-BG">
          · Season {currentId} ·
        </span>
        <span className="text-4xl font-Oswald font-bold tracking-[-1.44px] leading-[43px] whitespace-nowrap text-Content-Inverse-Primary-BG">
          OWO AIRDROP
        </span>
        <div className={cn("mt-2 min-w-[112px] bg-Backgroun-Primary rounded-md px-3 py-2 inline-flex flex-row justify-center items-center h-fit whitespace-nowrap text-Content-Accent1 text-[44px] font-Oswald font-bold tracking-[-1.76px] leading-[53px]", {
          'bg-Primitives-Green-700 text-Primitives-Green-900': isExpired
        })}>
          {owoValue}
        </div>
        <p className="mt-2 text-sm font-SFProDisplay font-bold">
          {settleStatus !== settleStatusEum.SETTLED ? `Results on ${dayjs(endTime).utc().format("MMM D, YYYY HH:mm:ss")} UTC` : claimMessage }
        </p>
      </div>
  )
}