import { useContext, useEffect, useMemo, useState } from "react";

import { useGetGas, useToast } from '@/hooks';
import { useClientProvider } from "client/ClientProvider";
import { sendMessage  } from "utils";
import { useTokenList } from 'hooks';
import { claimStatusEum } from "../const";
import { compare } from "compare-versions";
import { TaskContext } from "../../../utils/global";
import { Bugsnag } from "bugsnag";
import { queryKeyMap } from "@/consts";
import { useQueryClient } from "@tanstack/react-query";

export const useClaimOwO = ({ owoTokenAddress, claimStatus }: {owoTokenAddress: string, claimStatus?: claimStatusEum}) => {
  const { customerApi } = useClientProvider()
  const Toast = useToast();
  const { tokenList } = useTokenList();
  const { getGasFee } = useGetGas();
  const queryClient = useQueryClient();
  // 上链的查询数据结构（预估gas、领取）
  // 页面上的 Claim 按钮 loading 状态
  const [isClaiming, setIsClaiming] = useState(false);
  // 预估gas的展示弹窗
  const [isShowGas, setIsShowGas] = useState(false);
  // 领取 owo 成功弹窗
  const [successVisible, setSuccessVisible] = useState(false)
  const [curClaimStatus, setCurClaimStatus] = useState("");
  const { state: { userInfo } } = useContext(TaskContext);
  const { appVersion, platformOS } = userInfo || {};


  useEffect(()=>{
    window.addEventListener("message", onSuccess);
    document.addEventListener("message",onSuccess)
    return () =>{
        document.removeEventListener("message",onSuccess,false)
        window.removeEventListener("message", onSuccess,false);
    }
  },[])

  // gas费 原生币
  const symbol = useMemo(() => {
    const token = tokenList.find((item: any) => item.type === 'native');
    return token?.symbol || "";
  }, [tokenList]);

  const getUserOps = async () => {
    const res = await customerApi.wallets.claimTokenList({
      token_address: owoTokenAddress,
    });
    if(!res?.data.success) {
      setIsClaiming(false);
      return Promise.reject(res?.data.msgKey)
    }
    const claimData = res.data.obj;

    return claimData?.rows?.map((item) => {
      return {
        to: item?.target_address,
        value: item?.value,
        data: item?.call_data,
      };
    }) || [];
  }

  const toogleTransitionLoading = (isShow: boolean) => {
    // 调用somon _Layout 里的公用弹窗 <TransitionLoadingNew />
    const messageData = {
      action: 'popup',
      router: 'toogleTransitionLoading',
      params: {
        isShow
      }
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  }

  const onClaim = async () => {
    const isBefore = compare(
      appVersion || '0.0.1',
      '1.5.0',
      "<",
    );
    if(isBefore && platformOS !== 'web') {
      Toast.show("You need to upgrade to the latest version.");
      return
    } 
    if (!owoTokenAddress) return;
    setIsClaiming(true);
    try {
      const IUserOps = await getUserOps();
      const { data: gasData, error: gasError } = await getGasFee(IUserOps);
      setIsClaiming(false);
      if (!gasError) {
        sendMessage(JSON.stringify({   
          action: 'CLAIM_OWO',
          payload: {
            userOps: IUserOps,
            gas: gasData,
          },
        }));
      }
    } catch (error) {
      setIsClaiming(false);
      Toast.show("System error");
      return Promise.reject(error)
    }
  };

  const onSuccess=(msg: any)=>{
    try {
      const message=JSON.parse(msg.data)
      if(message?.code !== 'CLAIM_OWO') return
      if(message?.payload?.success){
        queryClient.invalidateQueries({ queryKey: [queryKeyMap.ACHIEVEMENT_DETAIL] });
        setSuccessVisible(true);
        setCurClaimStatus(claimStatusEum.CLAIMED);
      }
    } catch (error) {
      Bugsnag.notify(new Error(JSON.stringify(error))) 
    }
  }

  useEffect(() => {
    setCurClaimStatus(claimStatus || "");
  }, [claimStatus]);

  return {
    onClaim,
    isClaiming,
    setIsClaiming,
    isShowGas,
    setIsShowGas,
    symbol,
    toogleTransitionLoading,
    successVisible,
    setSuccessVisible,
    curClaimStatus,
    setCurClaimStatus,
  };
};
