import { useContext, useState, useCallback, useRef, useEffect } from 'react';
import { TaskHeader } from './TaskHeader';
import { TaskList } from './TaskList';
import { useGetTasksList, useGetTasksProgress } from '@/client';
import { TaskContext } from '@/utils/global';
import { useCustomerMe, useSocial, useToast } from '@/hooks';
import { EarlyBird } from '../EarlyBird';
import { compare, sendMessage } from 'utils';
// @ts-ignore
import Spinner from "/src/assets/spinner.svg?react";
import { SomonLottie } from '@ui/components';
import { ClaimBadgesTips } from '../ClaimBadgesTips';
import { useGetUnclaimedList } from '../ClaimBadges/hooks';
import { Overlay } from 'react-vant';

export const TaskCenter = () => {
  const { state: { userInfo } } = useContext(TaskContext);
  const { data: eallyBirdStatus, refetch: refetchEarlyBird } = useGetTasksProgress({
    idToken: userInfo?.idToken,
  });
  const { data: taskList = [], refetch: refetchList, isFetching: isListFetching } = useGetTasksList({
    idToken: userInfo?.idToken,
    clientType: userInfo?.platformOS,
    appVersion: userInfo?.appVersion
  });
  const {data: questData, refetch: refetchMe, isFetching: isPointFetching} = useCustomerMe();
  const [isVerify, setIsVerify] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const onceRef = useRef(false)
  const { getAcessToken } = useSocial();
  const isHidden = userInfo?.isHidden ?? true;
  const { unclaimedList, refetchUnclaimedList } = useGetUnclaimedList(false)
  const Toast = useToast();

  useEffect(() => {
    getToken();
    // 监听WebView发回的authCode
    window.addEventListener("message", handleEvent, false);
    return () =>
      window.removeEventListener("message", handleEvent, false);
  }, []);

  useEffect(() => {
    if (!!userInfo?.idToken) {
      refreshData();
      refetchUnclaimedList();
      refetchEarlyBird();
    }
  }, [userInfo]);

  const getToken = async () => {
    if (onceRef.current) return;
    const url = new URL(window.location.href);
    const code: any = url.searchParams.get('code');
    const state: any = url.searchParams.get('state');
    if(!!code) {
        onceRef.current = true;
        const authCode = {
          authCode: code,
          platform:
            state === "TWITTER_LINK" ? "twitter" : "discord",
        };
        const res = await getAcessToken(authCode, () => {
          onceRef.current = false;
        });
        if (authCode.platform === 'twitter' && res === 'success') {
          refetchMe();
        }
        if (res === 'success') {
          // twitter accessToken解析成功后自动verify
          setIsVerify(true);
        }
    }
  };

  const handleEvent = useCallback(async(e) => {
    // onceRef保证getAcessToken()只执行一次
    if (onceRef.current) return;
    let authCode;
    if (window?.ReactNativeWebView) {
      authCode = e?.data?.authCode;
    } else {
      try {
        authCode = JSON.parse(e.data);
      } catch (error) {
        // console.log('error', error)
      } 
    }

    // 如果收到的信息包含错误，则停止调用接口,同时停止按钮loading状态
    if(authCode?.error === 'access_denied') {
      setStopLoading(true);
      return;
    };
    
    if (authCode) {
      onceRef.current = true;
      const res = await getAcessToken(authCode, () => {
        onceRef.current = false;
      });
      if (res === 'success') {
        // twitter accessToken解析成功后自动verify
        refetchMe();
        setIsVerify(true);
      }
    }
  }, [userInfo]);

  const refreshData = () => {
    refetchList();
    refetchMe();
  };

  const toOwo = () => {
    if(!isShowOwoEntry) {
      Toast.show("You need to upgrade to the latest version.");
      return
    }
    const messageData = {
      action: 'popup',
      router: 'owo'
    };
    const message = JSON.stringify(messageData);
    sendMessage(message);
  };

  const hasClaimBadgesTips = !!unclaimedList.length;

    // 使用app打开 & 版本小于1.6.0 不展示owo入口
    const isShowOwoEntry = !(window?.ReactNativeWebView && (!!userInfo?.appVersion && compare(
      userInfo?.appVersion,
      "1.6.0",
      "<"
    )));

  return (
    <div className="pb-[140px]">
      <TaskHeader
        questData={questData}
      />
      <div className="w-full h-fit px-4">
        {hasClaimBadgesTips && <ClaimBadgesTips className='pt-4 px-0 pb-0'/>}
        {/* owo */}
        <div className="w-full h-fit min-h-[57px] mt-8 mb-[24px] bg-[url('/img/banners.webp')] bg-cover rounded-2xl relative pb-5 pt-[18px] flex items-center justify-center cursor-pointer" onClick={toOwo}>
          <div 
            className="flex items-center flex-wrap align-middle text-base font-SFProDisplay font-bold text-[#0a0a0a]"
          >
            <osp-image 
              src={`${process.env.VITE_PUBLIC_BASE_PATH}/img/bannerImg.webp`}
              class="absolute w-[72px] h-[72px] left-2 top-[-23px]"
               />
              <div className='flex items-center text-[18px] font-Oswald font-bold text-[#0a0a0a] uppercase'>MORE $owo, MORE POINTS!
              </div>
          </div>
        </div>
        {Object.keys(taskList)?.map((type, index) => {
          return (
            <TaskList
              key={`${index}_${type}`}
              data={taskList?.[type]}
              questData={questData}            
              type={type}
              refreshData={refreshData}
              isVerify={isVerify}
              isHidden={isHidden}
              isClaimBadge={!!unclaimedList?.length}
              className={hasClaimBadgesTips ? 'mt-0' : ''}
              stopLoading={stopLoading}
              setStopLoading={setStopLoading}
            />
          )
        })}
      </div>
      {window?.ReactNativeWebView
        ? <Overlay visible={isListFetching || isPointFetching}
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              "--rv-overlay-background-color": "rgba(0,0,0,0.2)"
            }}>
            <Spinner className="fill-[#6d6d6d] w-10 h-10 animate-spin" />
          </Overlay>
        : <SomonLottie visible={isListFetching || isPointFetching}/>
      }

      {eallyBirdStatus?.pop && <EarlyBird
        earlyBirdVisible={eallyBirdStatus?.pop}
        taskId={eallyBirdStatus?.taskId}
        isVerify={isVerify}
        inviteCode={userInfo?.inviteCode}
      />}
    </div>
  )
};
