class Message {
  private origin: string | undefined;
  static instance: any;

  static getInstance() {
    if(!this.instance) {
      this.instance = new Message();
    }
    return this.instance;
  }
  setPostOrigin(origin: string) {
    this.origin = origin;
  }
  sendMessage(message: string) {
    if(window?.ReactNativeWebView) {
      window?.ReactNativeWebView?.postMessage?.(message);
    }else {
      console.log('this.origin', this.origin)
      window?.parent?.postMessage?.(message, this.origin || "*");
    }
  }
}

export const sendMessage = (msg: string) => {
  const message = Message.getInstance();
  message.sendMessage(msg);
}

export const setPostOrigin = (origin: string) => {
  const message = Message.getInstance();
  message.setPostOrigin(origin);
}
