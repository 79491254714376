import { getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  type RemoteConfig,
  getValue as getFirebaseValue,
  fetchAndActivate,
} from "firebase/remote-config";
import { firebaseEnvMap, Environment } from './const';

const genRemoteConfig = () => {
  let remoteConfig: RemoteConfig;

  return (app?: FirebaseApp) => {
    if (remoteConfig) return remoteConfig;
    remoteConfig = getRemoteConfig(app);
    return remoteConfig;
  };
};

const getFirebaseConfig = genRemoteConfig();

// Initialize Firebase
export async function initFirebase(env: Environment) {
  const app = initializeApp(firebaseEnvMap[env].firebaseConfig);
  getAnalytics(app);
  const remoteConfig = getFirebaseConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 1;
  remoteConfig.defaultConfig = {};
  await fetchAndActivate(remoteConfig);
}

export function logEventAnalytics(eventName: any, params?: any) {
  const analytics = getAnalytics();

  return logEvent(analytics, `somon_${eventName}`, params);
}

/** page and screen */
export function logScreenView(params: { [key: string]: any; firebase_screen: string | undefined; firebase_screen_class: string | undefined; } | undefined) {
  const analytics = getAnalytics();

  return logEvent(analytics, "screen_view", params);
}

export const getValue = async (key: string) => {
  const remoteConfig = getFirebaseConfig();
  await fetchAndActivate(remoteConfig);

  return getFirebaseValue(remoteConfig, key);
};
