import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { Environment, ProxyOsp } from 'osp-proxy';
import { Customer } from 'api';
import { ClientContext } from './ClientContext';
// import { Buffer } from "buffer";
import { UserOperation } from "./type";
import { hexValue, hexlify } from "ethers/lib/utils";
export type { Environment } from 'osp-proxy'
// window.Buffer = Buffer;


const convertUserOperation = (userOperation: UserOperation) => {
  return {
    sender: userOperation.sender,
    nonce: hexValue(userOperation.nonce),
    init_code: hexlify(userOperation.initCode),
    call_data: hexlify(userOperation.callData),
    call_gas_limit: hexValue(userOperation.callGasLimit),
    verification_gas_limit: hexValue(userOperation.verificationGasLimit),
    pre_verification_gas: hexValue(userOperation.preVerificationGas),
    max_fee_per_gas: hexValue(userOperation.maxFeePerGas),
    max_priority_fee_per_gas: hexValue(userOperation.maxPriorityFeePerGas),
    paymaster_and_data: hexlify(userOperation.paymasterAndData),
    // signature: hexlify(userOperation.signature),
  };
}
const getSecurityData = async () => {
  return new Promise(async (resolve) => {
    const JWT_TOKEN = localStorage.getItem('somon-jwt')
    resolve({
      headers: {
        'JWT_TOKEN': JWT_TOKEN || null
      }
    })
  })
}
const addMiddleware = ({ ospClient, customerApi }) => {
  type IaddMiddlewareParameters = Parameters<
    typeof ospClient.txClient.addMiddleware
  >;
  const policyDataMiddleware: IaddMiddlewareParameters[0] = async function (
    userOperation,
    opt,
  ) {
    if (!opt) {
      opt = {};
    }
    const revertUserOperation = convertUserOperation(userOperation);
    const {
      data: {
        obj: { data: policy_data },
      },
    } = await customerApi.wallets.gasPolicy(revertUserOperation);

    opt.policy_data = policy_data;
    return [userOperation, opt];
  };

  ospClient.txClient.addMiddleware(policyDataMiddleware, 150);
};

type IClientProviderProps = {
  ospEnv: Environment;
  somonEnv: string;
  children: React.ReactNode;
  appId: string;
  saasId: string;
  isReady?: boolean;
  isPC?: boolean;
}

export const ClientProvider = ({ children, ospEnv, somonEnv, appId, saasId, isReady, isPC }: IClientProviderProps) => {

  const ospClient = ProxyOsp.getInstance({
    env: ospEnv as Environment,
    appId,
    loginFn: () => {

    }
  });
  const [isOspInitDone, setIsOspInitDone] = useState(false);

  const customerApi = new Customer({
    baseUrl: somonEnv,
    // @ts-ignore
    securityWorker: getSecurityData,
    baseApiParams: {
      secure: true,
      headers: {
        "saas_id": saasId,
      }
    }
  })

  useEffect(() => {
    const init = async () => {
      const isInitDone = !!(await ospClient.getOsp());
      setIsOspInitDone(isInitDone);
      if(!isInitDone) return;
      addMiddleware({ ospClient, customerApi });
    }

    isReady && init();
  }, [ospClient, customerApi, isReady]);

  return (
    <ClientContext.Provider
      value={{
        ospClient,
        customerApi,
        isOspInitDone,
        isPC
      }}
    >
      {children}
    </ClientContext.Provider>
  )
}

export const useClientProvider = () => {
  const { ospClient, customerApi, isOspInitDone, isPC } = useContext(ClientContext)
  return {
    ospClient,
    customerApi,
    isOspInitDone,
    isPC
  }
}
