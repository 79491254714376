export const TASK_TITLE = {
  owo: "$OwO Quests",
  social: "Social Tasks",
  twitter: "Twitter Quests",
  wallet: "Wallet Quests",
  tribe: "Tribe Quests",
  discord: "Discord Quests",
};

export const BUTTON_TEXT = ["", "Go", "Verify", "Check-in", "Go", "Get"];

export const SOCIAL_PLATFORM = ["twitter", "discord"];

export const TASK_ICON = {
  sign_in: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/check_in.svg`,
  megaphone: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/megaphone_post.svg`,
  like: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/social_like.svg`,
  liked: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/social_liked.svg`,
  post: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/social_post.svg`,
  comment: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/social_comment.svg`,
  follow: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/social_follow.svg`,
  subject_post_x: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_post.svg`,
  reply_post_x: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_comment.svg`,
  follow_x: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_osp.svg`,
  follow_x_somon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_somon.svg`,
  follow_x_evg: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_evg.svg`,
  follow_x_bread: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_bnb.svg`,
  follow_x_zeek: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_zeek.svg`,
  name_x: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_change_name.svg`,
  wallet_bind: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/wallet_bind.svg`,
  wallet_deposit: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/wallet_deposit.svg`,
  wallet_asset: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/wallet_assets.svg`,
  join_tribe: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/join_tribe.svg`,
  created_tribe: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/create_tribe.svg`,
  tribe_join_member: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/invite_tribe.svg`,
  create_join_first: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/tribe_thousand.svg`,
  join_osp_dc: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/discord_join_osp.svg`,
  join_dc: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/discord_join_somon.svg`,
  app_rate: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/app_rating.svg`,
  claim_badge: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/claim_badges.svg`,
  claim_owo: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/claim_owo.svg`,
  join_dc4: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/join_telegram.svg`,
  owo_asset: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/keep_owo.svg`,
  owo_asset_over_limit: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/owo_asset_over_limit.svg`,
  follow_x_realAllenNg: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_allen.svg`,
  follow_x_0xSeanTao: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/twitter_follow_somon.svg`,
  follow_x_dream: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/follow_dream.svg`,
  eoa_asset_fireflypass: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/eoa_fireflypass.svg`,
  eoa_asset_gensokishionlinev2: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/eoa_gensokishionlinev2.svg`,
  eoa_asset_persona_base: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/eoa_persona_base_eth.svg`,
  eoa_asset_persona_eth: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/eoa_persona_base_eth.svg`,
  eoa_asset_moongateoat: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/eoa_asset_moongateoat.webp`,
  purchase_membership: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/purchase_membership.svg`,
  invited_purchase_membership: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/invited_purchase_membership.svg`,
  high_upvote: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/high_upvote.svg`,
  tribe_recommended: `${process.env.VITE_PUBLIC_BASE_PATH}/img/task_icon/tribe_recommended.svg`,
};

export const POPUP_ROUTER = ["BindWallet", "CreateTribe"];

export const APP_STORE_ADDRESS = {
  android: "https://play.google.com/store/apps/details?id=com.social.monster",
  ios: "https://apps.apple.com/hk/app/somon-web3-social-forum/id6499516872",
};
