interface BADGE_OBJ {
  [key: string]: any;
}

export const BADGE_LIST:BADGE_OBJ = {
  "1": {
    icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge01.webp`,
    color: '#E8A03C',
    name: 'Bronze',
    desc: 'Top 10 with 1500+ points advance to the next league',
    minPoint: 1500,
  },
  "2": {
    icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge02.webp`,
    color: '#E0E0E0',
    name: 'Silver',
    desc: 'Top 10 with 3000+ points advance to the next league',
    minPoint: 3000,
  },
  "3": {
    icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge03.webp`,
    color: '#E8DD3E',
    name: 'Gold',
    desc: 'Top 10 with 7000+ points advance to the next league',
    minPoint: 7000,
  },
  "4": {
    icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge04.webp`,
    color: '#BB68FF',
    name: 'Obsidian',
    desc: 'Top 10 with 15000+ points advance to the next league',
    minPoint: 15000,
  },
  "5": {
    icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge05.webp`,
    color: '#41C6E8',
    name: 'Diamond',
    desc: 'Top 10 with 40000+ points advance to the next league',
    minPoint: 40000,
  },
  "6": {
    icon: `${process.env.VITE_PUBLIC_BASE_PATH}/img/leaderboard/badge06.webp`,
    color: '#D8A8FF',
    name: 'King',
    desc: 'Top 15 to stay in the King league',
    minPoint: 5000, // 王者已经不用分数判断是否保级了。
  }
}